<template>
  <FormPageLayout>
    <p class="text-2xl font-semibold text-site-purple">Follow Up Boss - Agent Revamp</p>
    <p>Manual Sign-up</p>
    <FormSection title="Sales Representative">
      <SalesRepSelect v-model="formModel.salesRepresentative"
        :errorMessage="v$.formModel.salesRepresentative.$error ? v$.formModel.salesRepresentative.$errors[0].$message : ''" />
    </FormSection>
    <!-- Basic Info -->
    <FormSection title="Client Info">
      <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email" />
      <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number" />
    </FormSection>
    <FormSection title="Referral?">
      <div class="field" :class="{ 'field-block-error': v$.formModel.referral.$error }">
        <p v-if="v$.formModel.referral.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.referral.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.referral" />
        <label>Yes</label>
        <br />
        <input type="radio" value="No" v-model="formModel.referral" />
        <label>No</label>
      </div>

      <FormSection v-if="formModel.referral === 'Yes'" embed="true">
        <VTextField :v="v$.formModel.referredFrom" placeholder="Referred by" />
        <p class="my-2 font-medium">or affiliate referral selection</p>
        <AffiliateSelect v-model="formModel.affiliate"
          :errorMessage="v$.formModel.affiliate.$error ? v$.formModel.affiliate.$errors[0].$message : ''" />
      </FormSection>
    </FormSection>
    <FormSection title="Who will the setup be enabled for?">
      <div class="field" :class="{ 'field-block-error': v$.formModel.setupEnabledFor.$error }">
        <p v-if="v$.formModel.setupEnabledFor.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.setupEnabledFor.$errors[0].$message }}
        </p>
        <input type="radio" value="All users" v-model="formModel.setupEnabledFor" />
        <label>All users within the FUB account <span class="text-gray-600 text-sm">(This option is the default behavior
            of our setup and is recommended for most teams and solo agents.)</span></label>
        <br />
        <input type="radio" value="Only me or a limited set of users" v-model="formModel.setupEnabledFor" />
        <label>A limited set of users in the FUB account <span class="text-gray-600 text-sm">(This option is a
            customization to our default setup and is an additional $200 for the custom work. Any changes to this after
            the setup is complete will be billed as hourly work.)</span></label>
      </div>
    </FormSection>
    <!-- Stage setup -->
    <FormSection title="Setting up stages for the revamp">
      <p><span class="link underline text-base font-normal" @click="showStagesModal = true"><font-awesome-icon
            icon="fa-solid fa-info-circle" /> see stage definitions</span></p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.stageSetup.$error }">
        <p v-if="v$.formModel.stageSetup.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.stageSetup.$errors[0].$message }}
        </p>
        <input type="radio" :value="stageSetupTypes.DEFAULT" v-model="formModel.stageSetup" />
        <label>Use the default stages provided by the revamp (recommended for most)</label>
        <br />
        <input type="radio" :value="stageSetupTypes.ZILLOW_TWO_WAY_SYNC" v-model="formModel.stageSetup" />
        <label>I want to use the Zillow Stages via the 2-way sync with Zillow - more info <a class="link"
            href="https://help.followupboss.com/hc/en-us/articles/8895957403927" target="_blank">here</a></label>
        <br />
        <input type="radio" :value="stageSetupTypes.CUSTOM" v-model="formModel.stageSetup" />
        <label>I would like to opt out of using the default stages used by the revamp. Instead, I have a requirement
          that the revamp be customized to use predefined stages that I will provide to Kee Technology Solutions. I
          acknowledge that I must provide names of my stages before work on the revamp can be started and <strong>I
            agree to pay a $400 customization fee.</strong> I acknowledge that my review is required before going live
          with the revamp. (Note: We will reach out to you after your submission to obtain a list of stages we can use.
          We will reach back out after the setup is complete to review the setup with you before we go live with
          it.)</label>
      </div>
      <StagesModal v-if="showStagesModal" @close="showStagesModal = false" />
    </FormSection>
    <!-- RealScout -->
    <FormSection title="Include RealScout Action Plans?"
      :helperText="`(Additional $${pricing.REAL_SCOUT.toLocaleString('en')})`"
      chipText="RealScout - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using RealScout</p>
      <ToggleSwitch v-model="formModel.includeRealScoutActionPlans" />
    </FormSection>
    <!-- Fello -->
    <FormSection title="Include Fello Action Plans?" :helperText="`(Additional $${pricing.FELLO.toLocaleString('en')})`"
      chipText="Fello - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Fello</p>
      <ToggleSwitch v-model="formModel.includeFelloActionPlans" />
    </FormSection>
    <!-- Conversion Monster -->
    <FormSection title="Include Conversion Monster Action Plans?"
      :helperText="`(Additional $${pricing.CONVERSION_MONSTER.toLocaleString('en')})`"
      chipText="Conversion Monster - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Conversion
        Monster</p>
      <ToggleSwitch v-model="formModel.includeConversionMonsterActionPlans" />
    </FormSection>
    <!-- Ylopo Users -->
    <FormSection title="Include Ylopo Action Plans?" :helperText="`(Additional $${pricing.YLOPO.toLocaleString('en')})`"
      chipText="Ylopo - Platform Pairing Add-On">
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Ylopo</p>
      <ToggleSwitch v-model="formModel.includeYlopoActionPlans" />
    </FormSection>
    <!-- SMS Add On -->
    <FormSection title="Include add-on SMS Drips?"
      :helperText="`(Additional $${pricing.SMS_DRIPS.toLocaleString('en')})`" chipText="SMS Add-On">
      <p class="mt-2 text-sm font-medium">If you currently use either <strong>Texting Betty</strong>,
        <strong>Leadngage</strong>, or <strong>StreetText</strong> for automated texting in FUB, we can add our SMS
        Drips to the setup we provide for an additional ${{ pricing.SMS_DRIPS }}.
      </p>
      <p class="mt-2 text-sm font-medium">Note: You can always sign up for this add-on at a later time if, in the
        future, you sign up for either Texting Betty, Leadngage or StreetText.</p>
      <p class="mt-2 text-sm font-medium">By making this selection you agree that you <strong>currently</strong> are
        using either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong>.</p>
      <ToggleSwitch v-model="formModel.includeSMSDrips" />

      <FormSection v-if="formModel.includeSMSDrips" :embed="true" title="Texting automation service you use">
        <VRadio :v="v$.formModel.textingService" :options="['Texting Betty', 'Leadngage', 'StreetText']" />
      </FormSection>
    </FormSection>
    <!-- Revamp Extended Add-on -->
    <FormSection title='Include add-on "Revamp Extended"?'
      :helperText="`(Additional $${pricing.REVAMP_EXTENDED.toLocaleString('en')})`" chipText="Revamp Extended Add-On">
      <p class="link underline text-base font-normal" @click="showRevampExtendedModal = true"><font-awesome-icon
          icon="fa-solid fa-info-circle" /> see what's included</p>
      <ToggleSwitch v-model="formModel.includeRevampExtended" />
      <RevampExtendedModal v-if="showRevampExtendedModal" @close="showRevampExtendedModal = false"
        :price="pricing.REVAMP_EXTENDED.toLocaleString('en')" />
    </FormSection>
    <!-- Ponds Add-on -->
    <FormSection title='Include Ponds add-on?' :helperText="`(Additional $${pricing.PONDS_ADD_ON.toLocaleString('en')})`" chipText="Ponds Add-On">
      <p>9 best practice ponds w/ cooresponding action plans for managing new leads, re-engagements, re-inquiries, resurrections, vendors, off markets, and referral agents.</p>
      <ToggleSwitch v-model="formModel.includePondsAddOn" />
    </FormSection>
    <!-- Spanish Content -->
    <FormSection title="Include add-on Spanish Action Plans?"
      :helperText="`(Additional $${pricing.SPANISH_ACTION_PLANS.toLocaleString('en')})`" chipText="Spanish Add-On">
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for our Canadian market material.</p>
      <p class="text-sm font-medium">Note: After the setup is complete, your review will be required before we can go
        live with it.</p>
      <p><a class="link underline text-base font-normal" @click="showSpanishContentModal = true"><font-awesome-icon
            icon="fa-solid fa-info-circle" /> more info</a></p>
      <SpanishContentModal v-if="showSpanishContentModal" @close="showSpanishContentModal = false" />
      <ToggleSwitch v-model="formModel.includeSpanishActionPlans" />

      <FormSection v-if="formModel.includeSpanishActionPlans" title="Default Language" embed="true">
        <p class="text-sm">Used when language preference is unknown for a lead</p>
        <VRadio :v="v$.formModel.defaultLanguage" :options="['English', 'Spanish']" />
      </FormSection>
    </FormSection>
    <!-- Lender Action Plans -->
    <FormSection title="Include add-on Lender Action Plans?"
      :helperText="`(Additional $${pricing.LENDER_ADD_ON.toLocaleString('en')})`" chipText="Lender Add-On">
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for lender action plans at this time.
      </p>
      <p class="text-sm font-medium">Note: By making this selection you agree to the terms that the lenders in your
        Follow Up Boss account are in fact "Lender" type users. The lender action plans will not work otherwise.</p>
      <ToggleSwitch v-model="formModel.includeLenderAddOn" />
    </FormSection>
    <!-- Custom Work -->
    <FormSection title="Include Custom Work">
      <div class="field" :class="{ 'field-block-error': v$.formModel.customWorkNeeded.$error }">
        <p v-if="v$.formModel.customWorkNeeded.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.customWorkNeeded.$errors[0].$message }}
        </p>
        <input type="radio" value="Hourly Work" v-model="formModel.customWorkNeeded" />
        <label>Hourly Work</label> <span class="text-sm"></span>
        <br />
        <input type="radio" value="Flat Rate Work" v-model="formModel.customWorkNeeded" />
        <label>Flat Rate Work</label> <span class="text-sm"></span>
        <br />
        <input type="radio" value="No" v-model="formModel.customWorkNeeded" />
        <label>No</label>
      </div>
      <FormSection v-if="customWorkNeeded === 'Flat Rate Work'" title="Custom Work Amount" :embed="true">
        <VNumberField :v="v$.formModel.customWorkAmount" isDollarAmount="true" />
      </FormSection>
      <FormSection v-if="customWorkNeeded && customWorkNeeded !== 'No'" title="Custom Work Notes" :embed="true">
        <VTextArea :v="v$.formModel.customWorkNotes" placeholder="Add notes here..." />
      </FormSection>
    </FormSection>
    <!-- Project Price -->
    <FormSection title="Discount">
      <VNumberField :v="v$.formModel.discountAmount" :isDollarAmount="true" />
    </FormSection>
    <FormSection title="Project Price">
      <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal"
          @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price
          breakdown</span></p>
    </FormSection>
    <!-- Deposit Payment -->
    <FormSection title="Payment">
      <div v-if="subscription">
        <p class="mt-5" @click="subscription = false">Payment Plan - $100 per month until project price is paid in full
        </p>
        <p class="mt-5">Due now is the first $100 installment payment. The remaining payments will auto charge exactly
          one month apart to the card provided below.</p>
      </div>
      <div v-else>
        <p class="mt-5" @click="subscription = true">Due now is a deposit of {{ depositDollarAmount }} USD</p>
        <div class="bg-gray-200 mt-5 p-5">
          <p>The deposit amount is half of the total project price {{ totalDollarAmount }}.</p>
          <p class="mt-2">A balance payment of {{ depositDollarAmount }} will be due within 2 weeks after the setup is
            complete.</p>
          <p class="mt-2"><strong>NOTE:</strong> The two week window to pay balance <strong>IS NOT CONTINGENT</strong>
            on a follow up call with you after the setup. Two weeks is a hard deadline.</p>
        </div>
      </div>
      <p class="font-semibold mt-10">Billing Information</p>
      <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
      <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
      <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
      <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
      <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
      <CountrySelect v-model="formModel.billing.country"
        :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''" />
      <VTextField :v="v$.formModel.billing.city" placeholder="City" />
      <StateSelect v-model="formModel.billing.state"
        :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
        :showProvinces="formModel.billing.country === 'Canada'" />
      <VTextField :v="v$.formModel.billing.zip"
        :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
      <p class="mt-5 font-semibold">Card Information</p>
      <SquarePayment ref="squarePayment" />
      <button @click="submit" :disabled="!canSubmit" class="button mt-5">{{ submitText }}</button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
    </FormSection>
    <PriceBreakdownModal v-if="showPriceBreakdownModal" @close="showPriceBreakdownModal = false" :pricing="pricing"
      :pricingMeta="pricingMeta" />
    <SubmittingModal v-if="isSubmitting" @close="(isSubmitting = false)" :loading="loading" :errorMessage="error"
      :noPayment="true">
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5" v-if="subscription">Info was submitted successfully and payment subscription was set
            up.</p>
          <p class="text-xl pt-5" v-else>Info was submitted successfully and the deposit payment of <strong
              class="font-semibold">{{ depositDollarAmount }}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import AffiliateSelect from '@/components/common/form/AffiliateSelect'
import SalesRepSelect from '../../common/form/SalesRepSelect'
import _pick from 'lodash/pick'
import StagesModal from './StagesModal'
import SpanishContentModal from "./SpanishContentModal";
import { STAGE_SETUP_TYPES, SETUP_TYPE } from '../../../constants';
import { getPricing } from '@/pricing'
import RevampExtendedModal from './RevampExtendedModal.vue'

export default {
  mixins: [formValidationMixin],
  components: {
    SalesRepSelect,
    StagesModal,
    SpanishContentModal,
    AffiliateSelect,
    RevampExtendedModal
  },
  data() {
    return {
      loading: false,
      error: '',
      isSubmitting: false,
      subscription: false,
      showStagesModal: false,
      showSpanishContentModal: false,
      showPriceBreakdownModal: false,
      showRevampExtendedModal: false,
      formModel: {
        salesRepresentative: "",
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        referral: "",
        referredFrom: "",
        affiliate: "",
        setupEnabledFor: "",
        includeLenderAddOn: false,
        includeRealScoutActionPlans: false,
        includeFelloActionPlans: false,
        includeConversionMonsterActionPlans: false,
        includeYlopoActionPlans: false,
        includeSpanishActionPlans: false,
        includePondsAddOn: false,
        includeRevampExtended: false,
        defaultLanguage: "",
        stageSetup: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
        includeSMSDrips: false,
        textingService: "",
        discountAmount: "",
        customWorkNeeded: "",
        customWorkAmount: "",
        customWorkNotes: ""
      },
    };
  },
  validations() {
    return {
      formModel: {
        salesRepresentative: { required },
        basicInfo: {
          firstName: { required },
          lastName: { required },
          email: { required },
          phone: { required }
        },
        referral: {},
        referredFrom: {},
        affiliate: {},
        setupEnabledFor: { required },
        stageSetup: { required },
        defaultLanguage: { required: requiredIf(this.formModel.includeSpanishActionPlans) },
        textingService: { required: requiredIf(this.formModel.includeSMSDrips) },
        discountAmount: {},
        customWorkNeeded: { required },
        customWorkAmount: { required: requiredIf(this.customWorkNeeded === 'Flat Rate Work') },
        customWorkNotes: { required: requiredIf(this.customWorkNeeded && this.customWorkNeeded !== 'No') },
        billing: {
          firstName: { required },
          lastName: { required },
          email: { required: requiredIf(this.subscription) },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    setupType() {
      return SETUP_TYPE.FUB_AGENT_REVAMP
    },
    pricing() {
      return getPricing(this.setupType, this.legacyPricing)
    },
    legacyPricing() {
      return this.$route.query.legacyPricing || ''
    },
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.customUserEnabling) {
        amount += this.pricing.CUSTOM_USER_ENABLING
      }
      if (this.customizeStages) {
        amount += this.pricing.CUSTOM_STAGES
      }
      if (this.formModel.includeSpanishActionPlans) {
        amount += this.pricing.SPANISH_ACTION_PLANS
      }
      if (this.formModel.includeLenderAddOn) {
        amount += this.pricing.LENDER_ADD_ON
      }
      if (this.formModel.includePondsAddOn) {
        amount += this.pricing.PONDS_ADD_ON
      }
      if (this.formModel.includeRevampExtended) {
        amount += this.pricing.REVAMP_EXTENDED
      }
      if (this.formModel.includeSMSDrips) {
        amount += this.pricing.SMS_DRIPS
        if (this.customizeStages) {
          amount += this.pricing.SMS_DRIPS_CUSTOM_STAGES
        }
      }
      if (this.formModel.includeFelloActionPlans) {
        amount += this.pricing.FELLO
      }
      if (this.formModel.includeConversionMonsterActionPlans) {
        amount += this.pricing.CONVERSION_MONSTER
      }
      if (this.formModel.includeRealScoutActionPlans) {
        amount += this.pricing.REAL_SCOUT
      }
      if (this.formModel.includeYlopoActionPlans) {
        amount += this.pricing.YLOPO
      }
      if (this.customWorkAmount) {
        amount += this.customWorkAmount
      }
      if (this.discountAmount) {
        amount -= this.discountAmount
      }
      return amount
    },
    pricingMeta() {
      return {
        customUserEnabling: this.customUserEnabling,
        customizeStages: this.customizeStages,
        customWorkAmount: this.customWorkAmount,
        discountAmount: this.discountAmount,
        includeSpanishActionPlans: this.formModel.includeSpanishActionPlans,
        includeLenderAddOn: this.formModel.includeLenderAddOn,
        includeSMSDrips: this.formModel.includeSMSDrips,
        includeRevampExtended: this.formModel.includeRevampExtended,
        includePondsAddOn: this.formModel.includePondsAddOn,
        includeFelloActionPlans: this.formModel.includeFelloActionPlans,
        includeConversionMonsterActionPlans: this.formModel.includeConversionMonsterActionPlans,
        includeRealScoutActionPlans: this.formModel.includeRealScoutActionPlans,
        includeYlopoActionPlans: this.formModel.includeYlopoActionPlans
      }
    },
    customWorkAmount() {
      return Number(this.formModel.customWorkAmount)
    },
    discountAmount() {
      return Number(this.formModel.discountAmount)
    },
    depositAmount() {
      return this.totalAmount / 2
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    depositDollarAmount() {
      return `$${this.depositAmount.toLocaleString('en')}`
    },
    customizeStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.CUSTOM
    },
    zillowStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.ZILLOW_TWO_WAY_SYNC
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    stageSetupTypes() {
      return STAGE_SETUP_TYPES
    },
    customUserEnabling() {
      return !!this.formModel.setupEnabledFor && this.formModel.setupEnabledFor !== 'All users'
    },
    customWorkNeeded() {
      return this.formModel.customWorkNeeded
    },
    discountProvided() {
      return !!this.formModel.discountAmount
    },
    submitText() {
      return this.subscription ? 'Submit and pay first installment of $100' : `Submit and pay ${this.depositDollarAmount} Deposit`
    }
  },
  watch: {
    customWorkNeeded(newVal) {
      if (newVal === 'No') {
        this.formModel.customWorkAmount = "",
          this.formModel.customWorkNotes = ""
      } else if (newVal === 'Hourly Work') {
        this.formModel.customWorkAmount = ""
      }
    }
  },
  methods: {
    buildNotes() {
      return _pick(this.formModel, [
        'setupEnabledFor',
        'includeSpanishActionPlans',
        'includeRealScoutActionPlans',
        'includeFelloActionPlans',
        'includeConversionMonsterActionPlans',
        'includeYlopoActionPlans',
        'includeRevampExtended',
        'includePondsAddOn',
        'defaultLanguage',
        'includeLenderAddOn',
        'includeSMSDrips',
        'stageSetup',
        'textingService',
        'customWorkNeeded',
        'customWorkAmount',
        'customWorkNotes',
        'discountAmount'
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        this.error = ''
        const notes = this.buildNotes();
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize()
        const payment = {
          note: 'Deposit Payment',
          isDeposit: true,
          billing: this.formModel.billing,
          locationId,
          sourceId
        }
        this.loading = true;
        this.isSubmitting = true;

        await this.$service.manualSignUp({
          basicInfo: this.formModel.basicInfo,
          notes,
          referredFrom: this.formModel.referredFrom,
          affiliate: this.formModel.affiliate,
          meta: {
            setupType: this.setupType,
            subscription: this.subscription,
            customStages: this.customizeStages,
            customUserEnabling: this.customUserEnabling,
            zillowStages: this.zillowStages,
            includeSpanishActionPlans: this.formModel.includeSpanishActionPlans,
            includeLenderAddOn: this.formModel.includeLenderAddOn,
            includeSMSDrips: this.formModel.includeSMSDrips,
            customWorkNeeded: this.customWorkNeeded,
            discountProvided: this.discountProvided,
            salesRepresentative: this.formModel.salesRepresentative,
            customWorkAmount: this.customWorkAmount,
            discountAmount: this.discountAmount,
            legacyPricing: this.legacyPricing,
            includeRevampExtended: this.formModel.includeRevampExtended,
            includePondsAddOn: this.formModel.includePondsAddOn,
            includeRealScoutActionPlans: this.formModel.includeRealScoutActionPlans,
            includeFelloActionPlans: this.formModel.includeFelloActionPlans,
            includeConversionMonsterActionPlans: this.formModel.includeConversionMonsterActionPlans,
            includeYlopoActionPlans: this.formModel.includeYlopoActionPlans
          },
          payment
        })

        this.loading = false
      } catch (e) {
        this.loading = false
        this.error = this.$service.getErrorMessage(e)
      }
    },
  },
};
</script>
<style scoped>
label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
