export const SETUP_TYPE = {
  FUB_AGENT_REVAMP: 'FUB_AGENT_REVAMP',
  FUB_LENDER_REVAMP: 'FUB_LENDER_REVAMP',
  FUB_AGENT_SMS: 'FUB_AGENT_SMS',
  TC_ASSIST: 'TC_ASSIST',
  BOSS_ASSIST: 'BOSS_ASSIST',
  SIERRA: 'SIERRA',
  KBC: 'KBC' // Kathleen Black Coaching
}

export const SMS_VENDOR = {
  TEXTING_BETTY: 'TEXTING_BETTY',
  LEADNGAGE: 'LEADNGAGE',
  STREET_TEXT: 'STREET_TEXT'
}

export const GUIDE_CODES = {
  SOLO: 'SOLO',
  TEAM_LEAD: 'TEAM_LEAD'
}

export const US_STATES = [
  "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut",
  "District of Columbia", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa",
  "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan",
  "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire",
  "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio",
  "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota",
  "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia",
  "Wisconsin", "Wyoming"
]

export const CANADA_PROVINCES = [
  "Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador",
  "Northwest Territories", "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island",
  "Quebec", "Saskatchewan", "Yukon"
]

export const STAGE_SETUP_TYPES = {
  DEFAULT: 'Default',
  ZILLOW_TWO_WAY_SYNC: 'Zillow Two Way Sync',
  CUSTOM: 'Custom'
}

export const CALENDLY_CALENDARS = {
  DISCOVERY: 'kts-consult/30-minute-zoom-discovery-call',
  LENDER_DISCOVERY: 'kts-consult/30-min-one-on-one-lender-discovery-call',
  TC_ASSIST_DISCOVERY: 'chelly-ktstc/30min',
  BOSS_ASSIST_DISCOVERY: 'kts-consult/30-min-bossassist-discovery-call'
}

export const CALENDLY_UTM = {
  DISCOVERY: {
    utmMedium: 'Discovery Call'
  },
  SIERRA_DISCOVERY: {
    utmCampaign: 'Sierra Revamp',
    utmSource: 'Sierra Landing Page',
    utmMedium: 'Discovery Call'
  },
  KBC: {
    utmCampaign: 'Kathleen Black Coaching',
    utmSource: 'Kathleen Black Coaching Page',
    utmMedium: 'Discovery Call'
  },
  CONVERSIONLY_DISCOVERY: (keeId) => ({
    utmCampaign: 'Conversionly',
    utmSource: 'Conversionly Page',
    utmMedium: 'Discovery Call',
    utmContent: keeId
  }),
  LENDER_DISCOVERY: {
    utmCampaign: 'Lender',
    utmSource: 'Lender Landing Page',
    utmMedium: 'Discovery Call'
  },
  TC_ASSIST_DISCOVERY: {
    utmCampaign: 'TC Assist',
    utmSource: 'TC Assist Landing Page',
    utmMedium: 'Discovery Call'
  },
  BOSS_ASSIST_DISCOVERY: {
    utmCampaign: 'BossAssist',
    utmSource: 'BossAssist Landing Page',
    utmMedium: 'Discovery Call'
  },
  WORKSHOP_DISCOVERY: {
    utmCampaign: 'Workshop',
    utmSource: 'Workshop Page',
    utmMedium: 'Discovery Call'
  },
  AFFILIATE_DISCOVERY: (affiliate) => ({ 
    utmCampaign: 'Affiliate',
    utmSource: 'Affiliate Page',
    utmMedium: 'Discovery Call',
    utmContent: affiliate
  })
}

export const SALES_TEAM = [
  "Rebekah Thomas",
  "Angie Mariani",
  "Jessie Browning",
  "Elena Kee",
  "Kendra Grunow",
  "Morgan Pacheco"
]

export const GROUP_TRAINING_EXTENSION_PRICE = 100

export const DEMO_VIDEO_ID = {
  FUB_AGENT_REVAMP: 'wZlGkv1lKc8',
  FUB_LENDER_REVAMP: '6F5QG0LpDi8',
  TC_ASSIST: 'czDj6HGk0jU',
  BOSS_ASSIST: 'UPKUlTCrtQ8'
}

export const COURSES = {
  FUB_CRASH_COURSE: 'FUB_CRASH_COURSE'
}